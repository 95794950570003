import { Controller } from 'stimulus';
import { useDebounce } from 'stimulus-use';

import coopenerEmailRegex from '../shared/coopener_email_regex';

const Routes = require('../formulaire/routes.js.erb');

export default class extends Controller {
  static targets = ['email', 'alert', 'alreadyClient'];

  static values = {
    loadingtext: String,
    alreadytext: String,
  }

  static debounces = ['checkForEmail'];

  controller = null;

  connect() {
    useDebounce(this, 350);
    this.checkForEmail();
  }

  checkForEmail() {
    this.unsetAlreadyClient();

    if (this.emailTarget.value.match(coopenerEmailRegex)) {
      this.verifyEmail({ email: this.emailTarget.value });
    }
  }

  async verifyEmail({ email }) {
    this.setLoading();

    this.abortPreviousFetch();
    this.controller = new AbortController();
    const response = await fetch(
      Routes.api_v1_verify_login_path({ email }),
      { signal: this.controller.signal },
    );
    if (response.ok) {
      this.setAlreadyClient();
    } else {
      this.unsetLoading();
    }
  }

  abortPreviousFetch() {
    if (this.controller) {
      this.controller.abort();
    }
  }

  setLoading() {
    this.alertTarget.innerText = this.loadingtextValue;
  }

  unsetLoading() {
    this.alertTarget.innerText = null;
  }

  setAlreadyClient() {
    this.alreadyClientTarget.value = true;
    this.alertTarget.innerText = this.alreadytextValue;
  }

  unsetAlreadyClient() {
    this.alreadyClientTarget.value = false;
    this.alertTarget.innerText = null;
  }
}
