import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['section', 'refreshEstimationField'];

  showChangedOverlay() {
    this.sectionTarget.classList.remove('hidden');
  }

  refreshEstimation() {
    this.refreshEstimationFieldTarget.value = true;
    this.element.closest('form').requestSubmit();
  }
}
