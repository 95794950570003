import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    paramName: String,
    paramValue: String,
  };

  submit() {
    const hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = this.paramNameValue;
    hiddenInput.value = this.paramValueValue;
    this.element.form.appendChild(hiddenInput);
    this.element.form.requestSubmit();
  }
}
