import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['unit'];

  updateUnit(event) {
    const sharePrice = 100;
    const amount = event.target.value * sharePrice;
    this.unitTarget.innerText = `part(s) pour ${amount} €`;
  }
}
