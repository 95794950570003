import { Controller } from '@hotwired/stimulus';

/**
 * This controller is used to manage actions on a tile UI element
 */
export default class extends Controller {
  static classes = ['active'];

  static targets = ['tileInput'];

  connect() {
    this.#toggleActiveClass(this.tileInputTarget);
  }

  toggle(event) {
    if (event.target !== this.tileInputTarget && event.target.getAttribute('for') !== this.tileInputTarget.getAttribute('id')) {
      this.tileInputTarget.checked ? this.#uncheck() : this.#check();
    }

    document.getElementsByName(this.tileInputTarget.name)
      .forEach((input) => this.#toggleActiveClass(input));

    return this.tileInputTarget.checked;
  }

  #check() {
    this.tileInputTarget.checked = 'checked';
  }

  #uncheck() {
    this.tileInputTarget.checked = null;
  }

  // NOTE: required for browsers non supporting :has CSS relational pseudo-class
  // @see: https://caniuse.com/css-has
  #toggleActiveClass(input, parent = input.parentElement) {
    if (parent === null) { return; }

    if (parent.dataset.controller !== this.scope.identifier) {
      this.#toggleActiveClass(input, parent.parentElement);
      return;
    }

    if (input.checked) {
      parent.classList.add(this.activeClass);
    } else {
      parent.classList.remove(this.activeClass);
    }
  }
}
