import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

const Routes = require('../formulaire/routes.js.erb');

export default class extends Controller {
  static targets = ['codeInput', 'description'];

  static values = { notFoundText: String };

  static debounces = ['handleInput'];

  connect() {
    useDebounce(this, 350);
  }

  handleInput() {
    if (this.codeInputTarget.value === '') {
      this.descriptionTarget.textContent = '';
      this.descriptionTarget.classList.add('hidden'); // Ensure you have a CSS rule to hide elements with the 'hidden' class
    } else {
      this.descriptionTarget.classList.remove('hidden');
      this.verifyDiscountCode(this.codeInputTarget.value);
    }
  }

  async verifyDiscountCode(discountCode) {
    await fetch(Routes.api_v1_discount_codes_path({ code: discountCode }))
      .then(async (response) => {
        if (response.ok) {
          const reply = await response.json();
          if (reply.data.attributes.validity === true) {
            this.descriptionTarget.classList.remove('alert-danger');
            this.descriptionTarget.textContent = reply.data.attributes.description;
            this.descriptionTarget.classList.add('alert-info');
          }
        } else {
          this.descriptionTarget.classList.remove('alert-info');
          this.descriptionTarget.classList.add('alert-danger');
          this.descriptionTarget.textContent = this.notFoundTextValue;
        }
      });
  }
}
