import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectedOptionTarifaireChangedField'];

  refreshForm() {
    this.selectedOptionTarifaireChangedFieldTarget.value = true;
    this.element.closest('form').requestSubmit();
  }
}
