import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['hidden'];

  static targets = ['item'];

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden';

    this.#toggleRevealClass();
  }

  toggle(event) {
    event.stopImmediatePropagation();
    this.itemTargets.forEach((item) => {
      if (event.params.mark !== item.dataset.revealMark && event.params.mark !== undefined) {
        this.#toggleRevealClass();
        return;
      }

      this.#checked(event, item)
        ? item.classList.remove(this.class)
        : item.classList.add(this.class);
    });
  }

  show(event) {
    event.stopImmediatePropagation();
    this.itemTargets.forEach((item) => {
      if (event.params.mark !== item.dataset.revealMark && event.params.mark !== undefined) {
        return;
      }

      item.classList.remove(this.class);
    });
  }

  hide(event) {
    event.stopImmediatePropagation();
    this.itemTargets.forEach((item) => {
      if (event.params.mark !== item.dataset.revealMark && event.params.mark !== undefined) {
        return;
      }

      item.classList.add(this.class);
    });
  }

  #checked(event, item) {
    if (event.params.input === undefined) {
      return Array.from(item.classList).includes(this.class);
    }

    const input = event.currentTarget.querySelector(`input[type=${event.params.input}]`);

    return input?.checked;
  }

  #toggleRevealClass() {
    this.itemTargets.forEach((item) => {
      const elements = item.dataset.revealMark === undefined
        ? this.scope.element.querySelectorAll('[data-reveal-input-param]')
        : this.scope.element.querySelectorAll(`[data-reveal-mark-param=${item.dataset.revealMark}]`);

      const inputParam = (element) => element.dataset.revealInputParam !== undefined;

      if (!Array.from(elements).some(inputParam)) {
        return;
      }

      const checked = (element) => {
        const elementInput = element.querySelector(`input[type=${element.dataset.revealInputParam}]`);
        return elementInput?.checked;
      };

      if (Array.from(elements).some(checked)) {
        item.classList.remove(this.class);
      } else {
        item.classList.add(this.class);
      }
    });
  }
}
